















































import store from './store';
import { namespace } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import * as components from './components';
import { debounce } from '@shared/utils/debounce';
import { TemplateListFilters, TemplatePreview, TemplateListFilterParams } from './models';
import { StateChanger } from 'vue-infinite-loading';
import { PAGINATION_MUTATIONS, PAGINATION_ACTIONS, PadinationLoadOptions } from '../../store/modules/paginator';
import { FamiliesType } from '@shared/models/FamiliesType';
const ns = namespace('templates');
const pg = namespace('templates/pg');

@Component({
    components: {
        ...components,
    },
    created() {
        this.$registerVuexModule('templates', store);
    },
})
export default class TemplatesPage extends Vue {
    @ns.State('selected') selected!: number[];
    @ns.Getter('filters') filters!: TemplateListFilters;
    @ns.Action('loadFilters') loadFilters!: () => Promise<void>;
    @ns.Mutation('updateSelected') updateSelected!: (paylod: number[]) => void;
    @ns.State('families') families!: FamiliesType[];

    @pg.State('dataId') infiniteId!: TemplateListFilterParams;
    @pg.State('params') params!: TemplateListFilterParams;
    @pg.Getter('pageItems') items!: TemplatePreview[];
    @pg.Action(PAGINATION_ACTIONS.load) loadPage!: (options: PadinationLoadOptions) => Promise<boolean>;
    @pg.Mutation(PAGINATION_MUTATIONS.setParams) setParams!: (paylod: Partial<TemplateListFilterParams>) => void;
    @pg.Mutation(PAGINATION_MUTATIONS.resetData) resetData!: () => void;

    filtersLoaded = false;

    async mounted() {
        await this.loadFilters();
        this.$nextTick(() => {
            if (!this.params.familyIds.length) {
                this.setParams({ familyIds: this.families.slice(0, 1).map(f => f.id) });
            }
            this.filtersLoaded = true;
        });
    }

    loadPageDebounced = debounce(async ({ event, reload = false }: { event?: StateChanger; reload: boolean }) => {
        if (reload) {
            this.updateSelected([]);
        }
        const res = await this.loadPage({ reload, scrollEvent: event });
    }, 500);

    get filtersParams() {
        const { query, sort, ...filtersParams } = this.params;
        return filtersParams;
    }

    get buttonText() {
        return this.selected.length ? 'CREATE BANNERS' : 'BEGIN BANNER EDITING';
    }

    get familyValue() {
        return this.families.find(f => this.params.familyIds[0] === f.id);
    }

    filterChanged(filters: Partial<TemplateListFilterParams>) {
        this.setParams(filters);
        this.loadPageDebounced({ reload: true });
        this.updateSelected([]);
    }

    goToEditor() {
        this.$router.push(`/editor/create?${this.selected.map(s => 'ids=' + s).join('&')}`);
    }

    destroyed() {
        this.resetData();
        this.updateSelected([]);
    }
}
