import { ActionTree } from 'vuex';
import { TemplatesPageState } from './index';
import { templatesAdapter } from '@api';
import familyAdapter from '@services/api/adapters/FamilyAdapter';
import { Sorting } from '@models';

const actions: ActionTree<TemplatesPageState, RootState> = {
    setPageIndex({ commit }, index: number): void {
        commit('setPageIndex', index); //NOTE: accessing the module action
    },
    async loadFilters({ commit }) {
        commit('setFilters', await templatesAdapter.getFilters());
        commit(
            'setFamilies',
            await familyAdapter.getPage({ size: 100, params: { query: '', sort: Sorting.ByName, sortAsc: true, options: 2 } })
        );
    },
};
export default actions;
