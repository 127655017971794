import { TemplatesPageState } from './index';
import { GetterTree } from 'vuex';

const getters: GetterTree<TemplatesPageState, RootState> = {
    filters(state) {
        return state.filters;
    },
};

export default getters;
