















import { Vue, Component, Prop } from 'vue-property-decorator';
import { TemplatePreview } from '../models';

@Component
export default class TemplateCard extends Vue {
    @Prop() template!: TemplatePreview;
    @Prop({ default: false, type: Boolean }) selected!: boolean;

    get resolution() {
        return `${this.template.width} x ${this.template.height} px`;
    }
}
