import { MutationTree } from 'vuex';
import { TemplatesPageState } from './index';
import { PageResult } from '@/shared/models';
import { TemplatePreview, TemplateListFilters, TemplateListFilterParams } from '@/modules/templates/models';
import { FamiliesType } from '@shared/models/FamiliesType';

const mutations: MutationTree<TemplatesPageState> = {
    setFilters(state, payload: TemplateListFilters) {
        state.filters = payload;
    },
    updateSelected(state, payload: number[]) {
        state.selected = payload;
    },
    setFamilies(state, payload: PageResult<FamiliesType>) {
        state.families = payload.rows;
    },
};
export default mutations;
