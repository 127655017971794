import { Module } from 'vuex';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import { TemplateListFilterParams, TemplatePreview, TemplateListFilters } from '@/modules/templates/models';
import createPaginationModule, { PAGINATION_MUTATIONS } from '@/store/modules/paginator';
import { templatesAdapter } from '@api';
import { FamiliesType } from '@shared/models/FamiliesType';

export interface TemplatesPageState {
    selected: number[];
    filters: TemplateListFilters;
    families: FamiliesType[];
}

function getInitialState(): TemplatesPageState {
    return {
        families: [],
        filters: {
            dimensions: [],
            sort: {},
            types: [],
        },
        selected: [],
    };
}

const module: Module<TemplatesPageState, RootState> = {
    namespaced: true,
    state: getInitialState(),
    getters,
    actions: {
        reset({ state, commit }) {
            Object.assign(state, getInitialState());
            commit(`pg/${PAGINATION_MUTATIONS.reset}`);
        },
        ...actions,
    },
    mutations,
    modules: {
        pg: createPaginationModule<TemplateListFilterParams, TemplatePreview>({
            api: templatesAdapter,
            initialState: () => ({
                page: {
                    size: 15,
                    index: 0,
                    rows: [],
                    totalItems: 0,
                },
                params: {
                    query: '',
                    tags: [],
                    ids: [],
                    dimensions: [],
                    sort: 0,
                    sortAsc: true,
                    types: [],
                    familyIds: [],
                },
                dataId: 1,
            }),
        }),
    },
};
export default module;
